<template>
    <GenericModal :modal-title="`Schedule for ${dayjs(appointmentDate).format('DD MMM YYYY')}`">
        <template #activator="{ show }">
            <Icon
                symbol="exclamation-fill"
                class="me-1"></Icon
            >{{ isClashing ? 'Your appointment time is clashing.' : '' }}
            <u
                class="caption underline-btn"
                @click="show">
                View availability
            </u>
        </template>
        <template v-slot="{ hide }">
            <div class="px-4 pb-3">
                <div
                    v-if="appointmentsForTheDayAsync.length > 0"
                    v-for="appt in appointmentsForTheDayAsync[0].appointmentRecords"
                    class="border rounded shadow-sm mb-3 p-3">
                    <div class="mt-1">
                        <span
                            class="badge"
                            :style="`background-color: ${appt.appointmentType.backgroundColor}`"
                            >{{ appt.appointmentType.name }}</span
                        >
                    </div>
                    <div class="ms-1">
                        <span>{{ dayjs(appt.appointment.startDateTime).format('hh:mm A') }}</span>
                        <span v-if="appt.appointmentType.name !== 'Follow-up exchange'"
                            >- {{ dayjs(appt.appointment.endDateTime).format('hh:mm A') }}</span
                        >
                    </div>
                    <div class="ms-1">
                        {{ appt.client.client.name }}
                    </div>
                </div>
            </div>
        </template>
    </GenericModal>
</template>

<script lang="ts" setup>
import { GenericModal, Icon } from '@/modules/core/components';
import { AppointmentDateGroupRecord } from '../../types/appointments';
import dayjs from 'dayjs';

interface Props {
    isClashing: boolean;
    appointmentDate: Date;
    appointmentsForTheDayAsync: AppointmentDateGroupRecord[];
}

const props = defineProps<Props>();
</script>

<style lang="scss">
.underline-btn {
    cursor: pointer;
    color: $black;
    text-decoration: underline;
    text-underline-offset: 2px;
}
.underline-btn:hover {
    color: $warning;
}
</style>
